import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./components/Pages/home";
import Intro from "./components/Pages/intro";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/home' element={<Home />} />
					<Route path='/' element={<Intro />} />

				</Routes>
			</BrowserRouter>
		</div>




	)
}


export default App;
