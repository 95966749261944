/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../components/styles/newRoadmap.css';
import './Pages/App.css';
import '../star.scss';

class Roadmap extends Component {


	render() {
		return (
			<div class="boxWrap5">

				<div data-aos="fade-up" class="utilH">Roadmap</div>


				<div class="wrapper">
					<div class="timeline">
						<dl class="timeline--entry">
							<dt class="timeline--entry__title">20%</dt>
							<dd class="timeline--entry__detail">We will pick 5 random GG NFT owners and ship them a print of their NFT! The winners will be announced wiithin the Discord channel.</dd>
						</dl>
						<dl class="timeline--entry">
							<dt class="timeline--entry__title">40%</dt>
							<dd class="timeline--entry__detail">We will airdrop 5 rare, unique Galactic Goddess NFTs to random holders.</dd>
						</dl>
						<dl class="timeline--entry">
							<dt class="timeline--entry__title">60%</dt>
							<dd class="timeline--entry__detail">We will donate up to 5% of sales to other women based NFT collections from emerging artists within the community.</dd>
						</dl>
						<dl class="timeline--entry">
							<dt class="timeline--entry__title">80%</dt>
							<dd class="timeline--entry__detail"><p>We will begin the voting and submission process for our quartely donations to various organizations and charities dedicated to women’s rights, womens empowerment and gender equality.</p>
								<p>While the organization voting process will began at 80%, we will donate 10% of sales to the chosen organization when the collection has sold out.</p></dd>
						</dl>
						<dl class="timeline--entry">
							<dt class="timeline--entry__title">100%</dt>
							<dd class="timeline--entry__detail">
								<p>We will begin working on the next release of GG. We will also start working on the Galactic Goddess episodes that will only be able to holders to watch.</p>
								<p>We’ll also discuss other giveaways and holder benefits in an exclusive GG Holder channel in our discord.</p></dd>
						</dl>

					</div>
				</div>
			</div>
		);
	}
}

export default Roadmap;

