import React, { Component } from 'react';
import './App.css';
import spaceship from '../../assets/spaceship.png';

class Intro extends Component {


    render() {
        return (
            <div class="popUpMsg">

                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>

                <div class="star"></div>
                <div class="meteor-1"></div>
                <div class="meteor-2"></div>
                <div class="meteor-3"></div>
                <div class="meteor-4"></div>
                <div class="meteor-5"></div>
                <div class="meteor-6"></div>
                <div class="meteor-7"></div>
                <div class="meteor-8"></div>
                <div class="meteor-9"></div>
                <div class="meteor-10"></div>
                <div class="meteor-11"></div>
                <div class="meteor-12"></div>
                <div class="meteor-13"></div>
                <div class="meteor-14"></div>
                <div class="meteor-15"></div>

                
                <div class="msg2">Our Galactic Goddesses are preparing to land on planet earth<span class="gFont">!</span></div>
                <div class="popUpMsgPicDiv"><img class="popUpMsgPic" src={spaceship} /></div>
                <div class="msg">Will you be attending their arrival?</div>
                <div class="button-29Div"><button onClick={() => window.location.href = '/home'} class="button-29">YES OF COURSE !</button></div>
            </div>

        );

    }
}

export default Intro;