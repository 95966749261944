import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {


	render() {

		return (

			<div class="footer">
				<div class="footer2">
				<div class="right2">

					<div class="discordF">
						<SocialIcon class="discord" url="https://discord.gg/UfaXmzcYDM" target="_blank" network="discord" bgColor="#ffffff" style={{ height: 25, width: 25 }} />
					</div>
					<div class="discordF">
						<SocialIcon class="discord" url="https://twitter.com/_GalacticNFTs" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 25, width: 25 }} />
					</div>
					<div class="discordF">
						<SocialIcon class="discord" url="https://instagram.com/galacticgoddessnft" target="_blank" network="instagram" bgColor="#ffffff" style={{ height: 25, width: 25 }} />
					</div>
			
				</div>
				<div class="copyright">Copyright © 2022, Galactic Goddess</div>
				<div class="copyright">All trademarks and copyrights belong to their respective owners</div>
				</div>
			</div>)
	}
}

export default Footer;