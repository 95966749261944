import React, { Component, useEffect } from 'react';
import './Pages/App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story extends Component {




    render() {
        return (

            <div class="boxWrap2">

                <div data-aos="fade-up" class="storyRight">
                    <div class="storyH">Our Story</div>
                    <p class="storyCon">
                    Greetings Earthlings, Shapeshifters, & Extraterrestrials! I have an important message to deliver straight from the Galaxy of Galactic Goddesses. This collection is dedicated to those who couldn’t help but be original in a “copy-paste world”.</p>
                    <p class="storyCon">It’s a celebration of eccentricity and ethereal beauty. We are highlighting the importance of women in each and every universe, Earth and Meta.</p>
                    <p class="storyCon">Galactic Goddess 🧞‍♀️ is an exclusive time capsule released NFT collection of 10,329 digital collectibles of galactic goddesses. Each goddess has various attribute and features, some of them occurring very rarely. UFO drops are quartely!🛸</p>
                   
                    
                </div>             



            </div>

        )
    }
}

export default Story;

