import React, { Component } from 'react';
import './Pages/App.css';
import g1 from '../assets/1g.jpg'
import g2 from '../assets/2g.jpg'
import g3 from '../assets/3g.jpg'
import picsGallery from '../assets/rarity-group-5.png';

class Rarity extends Component {


    render() {
        return (

            <div  class="boxWrap4">

                <div data-aos="fade-up" class="utilH">Rarity</div>
                <div data-aos="fade-up" class="uR">
                    <div class="rarityCon">
                        <div class="rarityCon1">While each Galactic Goddess NFT is definitely cool:</div>
                        <p></p>
                        <div class="rarityCon2">
                            <li>Some GG NFTs are <b>rare</b>.</li>
                            <li>Some are <b>very rare</b>.</li>
                            <li>And some are <b>exceptional</b>!</li>
                        </div>
                        <button class="button-87">Opensea</button>

                        <div data-aos="flip-left" data-aos-duration="3000" class="rarityBg">

                            <div class="rarityAndNum1">
                                <div>Galactic Security</div>
                                <div>9.68%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div> Galactic Gals</div>
                                <div>14.52%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div>Galactic Gents</div>
                                <div>14.52%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div>Galactic Socialties</div>
                                <div>10.64%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div>Galactic Citizens</div>
                                <div>30.98%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div>Galactic Unseen</div>
                                <div>1.93%</div>
                            </div>


                            <div class="rarityAndNum">
                                <div>Galactic Rebels</div>
                                <div>9.68%</div>
                            </div>


                            <div class="rarityAndNum">
                                <div>Galactic Originals</div>
                                <div>4.84%</div>
                            </div>

                            <div class="rarityAndNum">
                                <div>Galactic Special</div>
                                <div>3.2%</div>
                            </div>
                        </div>
                    </div>

                    <div><img data-aos="zoom-in" class="picSet" src={picsGallery} /></div>

                </div>

            </div>

        );

    }
}

export default Rarity;