import React, { Component } from 'react';
import './Pages/App.css';
import g1 from '../assets/1g.jpg'
import g2 from '../assets/2g.jpg'
import g3 from '../assets/3g.jpg'

class Utility extends Component {


    render() {
        return (

            <div class="boxWrap3">
            <div  data-aos="fade-up" class="utilH">Ownership Perks</div>
                <div data-aos="zoom-in" data-aos-duration="500"  class="uR">
                    <div class="container">
                        <div class="oPSubTitle">Charity</div>
                        <div><img class="ggPic" src={g1} /></div>
                        <div class="overlay">
                            <div class="oPSubTitle2">Charity</div>
                            <div class="text">10% of primary sales and 10% of royalties accrued on secondary sales each month will be donated to an women’s charity or organization that is dedicated towards women’s rights and empowerment and the promotion of gender equality.</div>
                        </div>
                    </div>
      
                    <div class="container">
                        <div class="oPSubTitle">Ownership</div>
                        <div><img class="ggPic" src={g2} /></div>
                        <div class="overlay">
                            <div class="oPSubTitle2">Ownership</div>
                            <div class="text">Commercial usage and ownership rights are given to the consumer of the individual galactic goddess and all of its intellectual property.</div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="oPSubTitle">Exclusives</div>
                        <div><img class="ggPic" src={g3} /></div>
                        <div class="overlay">
                            <div class="oPSubTitle2">Exclusives</div>
                            <div class="text">Galactic Galaxy citizens will have the chance to participate in VIP opportunities, including giveaways, voting / submission days, merchandise, future collection releases, suprise holder-only perks, art prints, and more!</div>
                        </div>
                    </div>

                </div>

            </div>


        );

    }
}

export default Utility;