import React, { Component } from 'react';
import './Pages/App.css';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div data-aos="fade-up" class="accordin reveal">

          <div class="faqH">FAQ</div>

          <details >
            <summary>What is Galactic Goddess?</summary>
            <div class="faq__content">
              <p>Once minting starts you will be able to mint here on our website at 35 FTM. You can visit our
                twitter and discord to ensure that you are using the legitimate T.C.E website</p>
            </div>
          </details>
          <details>
            <summary>When is the landing taking place?</summary>
            <div class="faq__content">
              <p>Minting for Teen Chimps Empire will begin on 15th March 2022.</p>

            </div>
          </details>
          <details>
            <summary>What is the mint price?</summary>
            <div class="faq__content">
              <p>Teen Chimps Empire will be stored on IPFS, a decentralized data storage that backs
                data with sustainable and perpetual endowments, allowing users and developers to truly
                store data forever.
              </p>

            </div>
          </details>

          <details>
            <summary>Will there be more collection releases?</summary>
            <div class="faq__content">
              <p>Yes, including the right to monetize commercially. Full intellectual property rights of each
                Teen Chimp are owned by its purchaser.
              </p>

            </div>
          </details>

          <details>
            <summary>How can I buy an GG?</summary>
            <div class="faq__content">
              <p>Because we believe every NFT collector deserves the high speed and low fees Fantom
                offers.</p>

            </div>
          </details>


          <details>
            <summary>How can I participate in the voting and submission process for women charities?</summary>
            <div class="faq__content">
              <p>Because we believe every NFT collector deserves the high speed and low fees Fantom
                offers.</p>

            </div>
          </details>

          <details>
            <summary>What are some perks of being a GG holder?</summary>
            <div class="faq__content">
              <p>Because we believe every NFT collector deserves the high speed and low fees Fantom
                offers.</p>

            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

