import './App.css';
import React, { Component } from 'react';
import Footer from "../footer";
import Story from "../story";
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Utility from '../utility';
import os from '../../assets/opensea.png';
import Roadmap from '../roadmap';
import Rarity from '../rarity';
import FAQ from '../faq';
import Carousel2 from '../carousel2';
import './sky.css';
import './star.scss';

var Scroll = require('react-scroll');


var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


const opensea = () => {
    window.open("https://opensea.io/collection/boys-club-official-nft");
}


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let mintStatus = '';
let loginWindow = 0;


// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [
    "0x617A4B4f35122293d9d0EA924b17055D4E550c44",
    "0xb00267C61b5d1BC380F1Afa106957697727cC519",
    "0xdc949797bc04d72b2a547d6bd796a51eb15d0a88",
    "0x85aa1db44F588209306fA6A48e4F2ACb0b20189B",
    "0x529e97d40eA063560d16b2a7B14610ea75F16EDf",
    "0x89ed12f746018fdc6ce04c1030dc246e8ba7cc3b",
    "0x6E3EDb79924803e6044704A970406DD3632cAaf9",
    "0x8847239E8d14EF122CFDeC7D0314aD27C3cD0D6b",
    "0xB51367929e153CadC8856695C165CeA08324C349",
    "0x9F322eA2F4Dd8Bbc1e02a7879E74Fb01914192c6",
    "0x8f7bA266AecEBbD2C7D98576cA638949d08BecD3",
    "0x650d492E2F68A665a5dc029bc3f255FE5ec366Fc",
    "0xEDaCA2961edF7592F6d05f5bCB6E0F9BE3787Def",
    "0x103a058412df3A36e5fb7511E637d3e53D91EEea",
    "0x1c28f041e4d70fecb05482a669f019377f1e20bc",
    "0x457a78141F8bEC38037E3886c5160dEC18637c14",
    "0x835B4485955ba8046231275318b579a1165cee2d",
    "0x12E862e0792d22C1B16Ca6F1df878f4Fc006Cf61",
    "0x002F874F745Ecb5b3D7FAe5196B3b037650Cf11d",
    "0x5be840577d46c18971446736578B5d12F60E604F",
    "0x5B22579045aa510d979766A47E58C78453EF13EC",
    "0x636Fb08404366EB0cFB8bda4a23e8B5BdD12ac80",
    "0x52bc7312B2AED57D30dddE7Bf8a3AD05BD1232F8",
    "0x3924bC70C63aA1c4bE7e94004DC869Fa633D3091",
    "0x2BDE2d294D7C775BA37E7B18be40D635A71E8349"
];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();


const ABI =

    [
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "_initBaseURI",
                    "type": "string"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [],
            "name": "ApprovalCallerNotOwnerNorApproved",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "ApprovalQueryForNonexistentToken",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "ApprovalToCurrentOwner",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "ApproveToCaller",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "BalanceQueryForZeroAddress",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "quantity",
                    "type": "uint256"
                }
            ],
            "name": "mint",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "MintToZeroAddress",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "MintZeroQuantity",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "OwnerIndexOutOfBounds",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "OwnerQueryForNonexistentToken",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "bool",
                    "name": "_state",
                    "type": "bool"
                }
            ],
            "name": "pause",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "approved",
                    "type": "bool"
                }
            ],
            "name": "setApprovalForAll",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "_newBaseURI",
                    "type": "string"
                }
            ],
            "name": "setBaseURI",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_maxperwallet",
                    "type": "uint256"
                }
            ],
            "name": "setMaxPerWallet",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_publicSaleCost",
                    "type": "uint256"
                }
            ],
            "name": "setPublicSaleCost",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_whitelistCost",
                    "type": "uint256"
                }
            ],
            "name": "setWhitelistCost",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "newWhitelistSigner",
                    "type": "bytes32"
                }
            ],
            "name": "setWhitelistSigner",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "TokenIndexOutOfBounds",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "TransferCallerNotOwnerNorApproved",
            "type": "error"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "transferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "TransferFromIncorrectOwner",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "TransferToNonERC721ReceiverImplementer",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "TransferToZeroAddress",
            "type": "error"
        },
        {
            "inputs": [],
            "name": "URIQueryForNonexistentToken",
            "type": "error"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "approved",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "Approval",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "bool",
                    "name": "approved",
                    "type": "bool"
                }
            ],
            "name": "ApprovalForAll",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "Transfer",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32[]",
                    "name": "_proof",
                    "type": "bytes32[]"
                },
                {
                    "internalType": "uint256",
                    "name": "quantity",
                    "type": "uint256"
                }
            ],
            "name": "whitelistMint",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "withdraw",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "baseURI",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getApproved",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                }
            ],
            "name": "isApprovedForAll",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "ownerOf",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "paused",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "publicmint_claimed",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "publicSaleCost",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes4",
                    "name": "interfaceId",
                    "type": "bytes4"
                }
            ],
            "name": "supportsInterface",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "index",
                    "type": "uint256"
                }
            ],
            "name": "tokenByIndex",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "index",
                    "type": "uint256"
                }
            ],
            "name": "tokenOfOwnerByIndex",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "tokenURI",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "whitelist_claimed",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "whitelistCost",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "whitelistSigner",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ];

const address = "0x7a97081bB9c68c987C40A5a860780388307aEa04";
let contract;


class Home extends Component {

    state = {
        walletAddress: "",
        totalSupply: "",
        currentPrice: "",
        nextPrice: "",
        nextSessionAmount: "",
        onlyLeftAmount: "",
        statusError: false,
        statusLoading: false,
        success: false,
        nftMintingAmount: '1',
        totalValue: "",
        presaleValue: "0",
        maxmint: '',
        _adminPanel: 0,
        _adminWhitelistAddresses: [],
        _adminWhitelistAddresses2: [],
        _maxTokensToBuy: "",
        _testingValue: '',
        _wlMint: '',
        _FinalResult: '',
        _wlMsg: 'Whitelist Mint',
        _mintStatus: '',
        _loginWindow: 0

    }

    onSubmitMinus = async event => {
        event.preventDefault();

        mintAmount = mintAmount - 1;

        if (mintAmount < 1) {
            mintAmount = 1
        }


        if (owner == account) {
            console.log("owner : " + owner)
            onlyLeft = 2222 - totalSupplyNFT;

            if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
            }

            valueOfNFTs = mintAmount * 0;
            wMintAmount = mintAmount;

            this.setState({ nftMintingAmount: mintAmount });

            this.setState({ totalValue: valueOfNFTs });
        } else {


            if (totalSupplyNFT < 2222) {

                onlyLeft = 10 - publicSale;

                if (mintAmount > onlyLeft) {
                    mintAmount = onlyLeft;
                }
                valueOfNFTs = mintAmount * 20000000000000000;
                wMintAmount = mintAmount;


                this.setState({ nftMintingAmount: mintAmount });

                this.setState({ totalValue: valueOfNFTs });

            }
        }
    }

    onSubmitPlus = async event => {
        event.preventDefault();

        //,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
        mintAmount = mintAmount + 1;

        if (owner == account) {
            console.log("owner : " + owner)
            onlyLeft = 2222 - totalSupplyNFT;

            if (mintAmount > onlyLeft) {
                mintAmount = onlyLeft;
            }

            valueOfNFTs = mintAmount * 0;
            wMintAmount = mintAmount;


            this.setState({ nftMintingAmount: mintAmount });

            this.setState({ totalValue: valueOfNFTs });
        } else {

            if (totalSupplyNFT < 2222) {

                onlyLeft = 10 - publicSale;
                console.log(onlyLeft);

                if (mintAmount > onlyLeft) {
                    mintAmount = onlyLeft;
                }
                valueOfNFTs = mintAmount * 20000000000000000;
                wMintAmount = mintAmount;

                this.setState({ nftMintingAmount: mintAmount });

                this.setState({ totalValue: valueOfNFTs });

            }
        }
    }

    onSubmit2 = async event => {
        event.preventDefault();

        console.log(this.state.walletAddress);

        try {
            let owner = await contract.methods.owner().call();


            if (account != owner) {


                try {


                    console.log("minAmount:" + mintAmount);
                    console.log("valueOfNFTs:" + valueOfNFTs);


                    this.setState({ statusError: false, statusLoading: true });
                    await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 1 });
                    this.setState({ statusLoading: false, success: true });
                    console.log("Mint Amount :" + this.state.mintAmount);

                } catch (err) {
                    this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                    console.log(err);


                }
            } else {

                try {


                    console.log("minAmount:" + mintAmount);
                    console.log("valueOfNFTs:" + valueOfNFTs);


                    this.setState({ statusError: false, statusLoading: true });
                    await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 0 });
                    this.setState({ statusLoading: false, success: true });
                    console.log("Mint Amount :" + this.state.mintAmount);

                } catch (err) {
                    this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                    console.log(err);


                }






            }
        } catch (err) {

            console.log(err);

        }
    }

    walletConnect = async event => {
        event.preventDefault();

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "bf933c3446b3464c988114813a1360ac" // required
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });



        const provider = await web3Modal.connect();

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        const web3 = new Web3(provider);
        console.log("provider : " + provider);
        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
            console.log(chainId);
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
            console.log(info);
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
            console.log(error);
        });

        // test if wallet is connected
        if (web3Modal.cachedProvider) {
            // connected now you can get accounts
            console.log("provider :" + web3Modal.cachedProvider);
            const accounts = await web3.eth.getAccounts();

            account = accounts[0];
            this.setState({ walletAddress: account });

            contract = new web3.eth.Contract(ABI, address);
            console.log("contract :" + contract);

            if (provider) {


                (async () => {


                    if (provider != "walletconnect") {



                        const chainId = 1;

                        if (window.ethereum.networkVersion !== chainId) {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(chainId) }],
                                });
                            } catch (err) {
                                // This error code indicates that the chain has not been added to MetaMask.
                                if (err.code === 4902) {
                                    await window.ethereum.request({
                                        method: 'wallet_addEthereumChain',
                                        params: [
                                            {
                                                chainName: 'Ethereum Mainnet',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                                                rpcUrls: ['https://etherscan.io'],
                                            },
                                        ],
                                    });
                                }
                            }
                        }





                        try {

                            mintStatus = await contract.methods.paused().call();
                            this.setState({ _mintStatus: mintStatus });
                            console.log("mintStatus :" + mintStatus);



                            totalSupplyNFT = await contract.methods.totalSupply().call();
                            this.setState({ totalSupply: totalSupplyNFT });
                            console.log("Total Supply:" + totalSupplyNFT);



                            publicSale = await contract.methods.balanceOf(account).call();
                            this.setState({ myNFTWallet: publicSale });

                            owner = await contract.methods.owner().call();
                            console.log("Owner" + owner);

                            if (owner == account) {
                                console.log("owner : " + owner)
                                onlyLeft = 2222 - totalSupplyNFT;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }

                                valueOfNFTs = mintAmount * 0;
                                wMintAmount = mintAmount;


                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            } else {
                                mintAmount = 1;

                                if (totalSupplyNFT == 2222) {

                                    onlyLeft = 2222 - totalSupplyNFT;
                                    mintAmount = onlyLeft;
                                    this.setState({ msg: "SOLD OUT" });

                                } else {
                                    mintAmount = 1;
                                    onlyLeft = 10 - publicSale;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }
                                    //mintAmount = onlyLeft;

                                    valueOfNFTs = mintAmount * 20000000000000000;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                }
                            }


                        } catch (err) {
                            console.log("err: " + err);

                        }
                    } else {



                        if (WalletConnectProvider.networkVersion !== chainId) {
                            try {
                                await WalletConnectProvider.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(chainId) }],
                                });
                            } catch (err) {
                                // This error code indicates that the chain has not been added to MetaMask.
                                if (err.code === 4902) {
                                    await window.ethereum.request({
                                        method: 'wallet_addEthereumChain',
                                        params: [
                                            {
                                                chainName: 'Ethereum Mainnet',
                                                chainId: web3.utils.toHex(chainId),
                                                nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                                                rpcUrls: ['https://etherscan.io'],
                                            },
                                        ],
                                    });
                                }
                            }
                        }




                        try {

                            mintStatus = await contract.methods.paused().call();
                            this.setState({ _mintStatus: mintStatus });
                            console.log("mintStatus2 :" + mintStatus);


                            totalSupplyNFT = await contract.methods.totalSupply().call();
                            this.setState({ totalSupply: totalSupplyNFT });

                            console.log("Total Supply:" + totalSupplyNFT);

                            publicSale = await contract.methods.balanceOf(account).call();
                            this.setState({ myNFTWallet: publicSale });

                            owner = await contract.methods.owner().call();
                            console.log("Owner" + owner);

                            if (owner == account) {
                                console.log("owner : " + owner)
                                onlyLeft = 2222 - totalSupplyNFT;

                                if (mintAmount > onlyLeft) {
                                    mintAmount = onlyLeft;
                                }

                                valueOfNFTs = mintAmount * 0;

                                wMintAmount = mintAmount;


                                this.setState({ nftMintingAmount: mintAmount });

                                this.setState({ totalValue: valueOfNFTs });
                            } else {
                                mintAmount = 1;

                                if (totalSupplyNFT == 2222) {

                                    onlyLeft = 0;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }
                                    this.setState({ msg: "SOLD OUT" });

                                } else {
                                    mintAmount = 1;
                                    onlyLeft = 10 - publicSale;

                                    if (mintAmount > onlyLeft) {
                                        mintAmount = onlyLeft;
                                    }

                                    valueOfNFTs = mintAmount * 20000000000000000;
                                    wMintAmount = mintAmount;


                                    this.setState({ nftMintingAmount: mintAmount });

                                    this.setState({ totalValue: valueOfNFTs });
                                }
                            }


                        } catch (err) {
                            console.log("err: " + err);

                        }
                    }
                })();

                //.....................................................................//






                // Legacy providers may only have ethereum.sendAsync
                const chainId = await provider.request({
                    method: 'eth_chainId'
                })

            } else {

                // if the provider is not detected, detectEthereumProvider resolves to null
                console.error('Please install a Valid Wallet');
                alert('A valid provider could not be found!');

            }






        }

    }

    walletDisconnect = async event => {
        event.preventDefault();


        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "bf933c3446b3464c988114813a1360ac" // required
                }
            }
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });



        // disconnect wallet
        web3Modal.clearCachedProvider();
        window.location.reload();

    }

    whitelistMint = async event => {
        event.preventDefault();


        //	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
        console.log("Root Hash: ", rootHash);
        console.log("Root HashHash: ", rootHashHash);

        // ***** ***** ***** ***** ***** ***** ***** ***** // 

        // CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
        // required to derive the root hash of the Merkle Tree

        // ✅ Positive verification of address
        //const claimingAddress = leafNodes[0];
        //console.log("Claiming Address:"+ claimingAddress);
        // ❌ Change this address to get a `false` verification
        const claimingAddress = keccak256(account);

        // `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
        // be required to derive the Merkle Trees root hash.
        const hexProof = merkleTree.getHexProof(claimingAddress);
        console.log("HexProof:" + hexProof);

        // ✅ - ❌: Verify is claiming address is in the merkle tree or not.
        // This would be implemented in your Solidity Smart Contract
        console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
        FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
        this.setState({ _FinalResult: FinalResult });

        if (FinalResult) {
            try {
                this.setState({ statusError: false, statusLoading: true });
                await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
                this.setState({ statusLoading: false, success: true });
                console.log("Mint Amount :" + this.state.wMintAmount);

            } catch (err) {
                this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
                console.log(err);
            }
        } else {
            wlMsg = "Not Eligible for WL"
            this.setState({ _wlMsg: wlMsg });
        }

    }

    closeWindow = async event => {
        event.preventDefault();

        loginWindow = 0;
        this.setState({ _loginWindow: loginWindow });
        console.log("status" + this.state._loginWindow);
    }

    async componentDidMount() {
        loginWindow = 1;
        this.setState({ _loginWindow: loginWindow });
        console.log("status" + this.state._loginWindow);
    }

    loginWindow = async event => {
        event.preventDefault();
        loginWindow = 1;
        this.setState({ _loginWindow: loginWindow });
        console.log("status" + this.state._loginWindow);
    }

    render() {

        return (

            <div class="allWrap">

                <div class="light">

                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                    <div class="star"></div>
                    <div class="meteor-1"></div>
                    <div class="meteor-2"></div>
                    <div class="meteor-3"></div>
                    <div class="meteor-4"></div>
                    <div class="meteor-5"></div>
                    <div class="meteor-6"></div>
                    <div class="meteor-7"></div>
                    <div class="meteor-8"></div>
                    <div class="meteor-9"></div>
                    <div class="meteor-10"></div>
                    <div class="meteor-11"></div>
                    <div class="meteor-12"></div>
                    <div class="meteor-13"></div>
                    <div class="meteor-14"></div>
                    <div class="meteor-15"></div>

                    <div class="title">Galactic Goddess</div>

                    <div class="headers" id="navbarTop">
                        <div class="navBar">

                            <div class="row1">
                                <div><Link id="fontSize" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
                                <div><Link id="fontSize" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
                                <div><Link id="fontSize" to="rarity" spy={true} smooth={true} duration={550}>Rarities</Link></div>
                                <div><Link id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

                            </div>
                        </div>


                        <div class="socialContainer">
                          
                            <div class="discord">
                                <SocialIcon class="discord" url="https://discord.gg/UfaXmzcYDM" target="_blank" network="discord" bgColor="#ffff" style={{ height: 25, width: 25 }} />
                            </div>

                            <div class="discord">
                                <SocialIcon class="discord" url="https://twitter.com/_GalacticNFTs" target="_blank" network="twitter" bgColor="#ffff" style={{ height: 25, width: 25 }} />
                            </div>

                            <div class="discord">
                                <SocialIcon class="discord" url="https://instagram.com/galacticgoddessnft" target="_blank" network="instagram" bgColor="#ffff" style={{ height: 25, width: 25 }} />
                            </div>
                            
                            <div class="discord2">
                                <SocialIcon class="discord2" url="https://discord.gg/UfaXmzcYDM" target="_blank" network="discord" bgColor="#ffff" style={{ height: 40, width: 40 }} />
                            </div>

                            <div class="discord2">
                                <SocialIcon class="discord2" url="https://twitter.com/_GalacticNFTs" target="_blank" network="twitter" bgColor="#ffff" style={{ height: 40, width: 40 }} />
                            </div>

                            <div class="discord2">
                                <SocialIcon class="discord2" url="https://instagram.com/galacticgoddessnft" target="_blank" network="instagram" bgColor="#ffff" style={{ height: 40, width: 40 }} />
                            </div>
                        </div>
                    </div>

                    <Carousel2 />

                    <div class="mintAndOS">
                        <div class="mintAndOS2">
                            <div class="containerOpensea">
                                <div class="center">
                                    <button class="btn">
                                        <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                        </svg>
                                        <span class="btn2Font">OPENSEA</span>
                                    </button>
                                </div>
                            </div>
                            <div class="containerOpensea">
                                <div class="center">
                                    <button class="btn">
                                        <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                        </svg>
                                        <span class="btn2Font" button onClick={() => window.location.href = '/mint'}>MINT HERE</span>
                                    </button>
                                </div>
                            </div>                        </div>
                    </div>

                    <Element name="story">
                        <Story />
                    </Element>
                    <Utility />
                    <Element name="rm">
                        <Roadmap />
                    </Element>
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>

                    <Element name="rarity">
                        <Rarity />
                    </Element>

                    <Element name="faq">
                        <FAQ />
                    </Element>

                </div>
                <Footer />

            </div >)
    }
}

export default Home;
